import 'isomorphic-fetch';
import React from 'react';
import { CartProvider } from 'react-use-cart';
import { CookiesProvider } from 'react-cookie';

import 'gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css'
import 'react-medium-image-zoom/dist/styles.css'

import './src/components/breadcrumb.css'
import './src/components/RcNotification/index.css'

import { CheckoutProvider } from './src/context/Checkout';

export const wrapRootElement = ({ element }) => {
  return (
    <React.StrictMode key="strict-mode-wrapper">
      <CookiesProvider>
        <CartProvider
          id="sensatia-cart"
          onSetItems={() => {}}
        >
          <CheckoutProvider>
            {element}      
          </CheckoutProvider>
        </CartProvider>
      </CookiesProvider>
    </React.StrictMode>
  );
};

export const onClientEntry = () => {
  if (typeof window !== 'undefined') {
    const currentPath = window.location.pathname;
    const userLanguage = navigator.language || navigator.userLanguage;
    if (userLanguage.startsWith('id') && !currentPath.startsWith('/id/')) {
      window.location.replace('/id' + currentPath);
    } else if (userLanguage.startsWith('ja') && !currentPath.startsWith('/jp/')) {
      window.location.replace('/jp' + currentPath);
    }

    function reportErrorToBetterStack(message, source, lineno, colno, error) {
      if (message && message.includes('Minified React error #418')) {
        return;
      }

      const payload = {
        service: "sensatia-browser-errors",
        message,
        source,
        line: lineno,
        column: colno,
        error: error ? error.stack : null,
        url: window.location.href,
        userAgent: navigator.userAgent,
      };
      fetch('/.netlify/functions/browser-errors', { method: 'POST', body: JSON.stringify(payload) }).catch(console.error)
    }

    // Global error handler for uncaught errors
    window.onerror = (message, source, lineno, colno, error) => {
      reportErrorToBetterStack(message, source, lineno, colno, error);
      return false; // Prevent default error logging
    };

    // Optional: Handle unhandled promise rejections
    window.onunhandledrejection = (event) => {
      reportErrorToBetterStack(
        event.reason.message || "Unhandled rejection",
        "",
        0,
        0,
        event.reason
      );
    };
  }
};